import TextInput from './TextInput';
import VoiceInput from './VoiceInput';

import './ChatInput.css';
import { useState } from 'react';
import classNames from 'classnames';
import useChatStore from '../../hook/useStore';
import InputButton from './InputButton';

const ChatInput = ({ startRecording, stopRecording }: any) => {
  const [message, setMessage] = useState('');

  const setChatMessage = useChatStore((state) => state.addChatMessage);
  const isVoiceRecording = useChatStore((state) => state.isRecording);

  const handleSend = () => {
    if (message.trim()) {
      setChatMessage({
        text: message,
        sender: 'You',
      });
      setMessage('');
    }
  };

  const withVoice = !!message.length;

  return (
    <form>
      <div
        className={classNames('chat-input', {
          'show-voice-recorder': isVoiceRecording,
          "with-voice": !withVoice,
        })}
      >
        <TextInput
          setMessage={setMessage}
          message={message}
          isVoiceRecording={isVoiceRecording}
        />
        {withVoice ? (
          <InputButton message={message} handleSend={handleSend} />
        ) : (
          <VoiceInput
            hide={withVoice}
          />
        )}
      </div>
    </form>
  );
};

export default ChatInput;
