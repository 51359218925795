const Logo = () => {
  return (
    <svg
      width="155"
      height="35"
      viewBox="0 0 155 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0533 14.0356C15.9137 14.0356 17.6981 14.7715 19.0145 16.0818C20.331 17.392 21.0719 19.1695 21.0745 21.0237H28.1056V0H21.0497V8.90207C19.1849 7.82881 17.0974 7.19768 14.9486 7.05755C12.7999 6.91742 10.6476 7.27206 8.65845 8.094C6.66929 8.91594 4.89659 10.1831 3.47762 11.7975C2.05865 13.4118 1.03146 15.33 0.475599 17.4035C-0.0802648 19.4769 -0.149897 21.6501 0.272093 23.7547C0.694084 25.8593 1.59638 27.8389 2.90911 29.5402C4.22184 31.2415 5.9098 32.6188 7.8423 33.5656C9.77479 34.5124 11.9 35.0032 14.0533 35H28.1006V28.002H14.0136C12.1554 27.9967 10.3754 27.256 9.06522 25.9427C7.755 24.6293 7.02189 22.8511 7.02715 20.999C7.03242 19.1469 7.77563 17.3728 9.09329 16.0669C10.4109 14.7611 12.1951 14.0304 14.0533 14.0356Z"
        fill="#00A688"
      />
      <path
        d="M147.654 10.5786C144.409 10.5786 142.617 13.2492 142.612 15.7319C142.616 16.576 142.826 17.4066 143.223 18.1519C143.621 18.8972 144.195 19.5349 144.895 20.0099L141.997 24.4609H146.011L148.343 20.8457H151.142V24.4609H154.511V10.6132L147.654 10.5786ZM151.127 15.6825V17.6904H147.738C147.495 17.6741 147.257 17.6099 147.038 17.5014C146.82 17.3929 146.625 17.2423 146.465 17.0583C146.306 16.8744 146.184 16.6607 146.108 16.4296C146.032 16.1985 146.002 15.9547 146.021 15.7121C146.021 14.5499 146.85 13.6795 147.743 13.6795H151.127V15.6825Z"
        fill="#00A688"
      />
      <path
        d="M137.519 10.8805V10.6282H134.17V21.3058H131.6C131.219 21.3059 130.841 21.2301 130.49 21.083C130.139 20.9359 129.82 20.7204 129.554 20.4491C129.287 20.1778 129.077 19.8562 128.937 19.5031C128.797 19.15 128.729 18.7725 128.737 18.3928V10.6035H125.387V18.6698C125.486 20.2312 126.177 21.6965 127.321 22.7678C128.465 23.839 129.975 24.4357 131.545 24.4363H139.162V21.3454H137.509L137.519 10.8805Z"
        fill="#00A688"
      />
      <path
        d="M117.996 10.564V21.3009H115.431C115.048 21.3003 114.67 21.2237 114.317 21.0755C113.965 20.9274 113.645 20.7107 113.378 20.4381C113.11 20.1655 112.9 19.8424 112.759 19.4879C112.618 19.1333 112.55 18.7543 112.558 18.3731V10.564H109.204V18.655C109.303 20.2207 109.997 21.6899 111.145 22.7638C112.292 23.8377 113.807 24.4357 115.381 24.4364H121.36V10.8162V10.564H117.996Z"
        fill="#00A688"
      />
      <path
        d="M101.829 15.9644H96.3312V10.5737H92.957L92.962 24.4362H96.3312V19.0653H101.829V24.4362H105.203V10.5935H101.829V15.9644Z"
        fill="#00A688"
      />
      <path
        d="M77.8594 24.4361V10.5884H84.3596C87.6047 10.5884 89.4009 13.259 89.4059 15.7417C89.4125 16.4063 89.2868 17.0657 89.0361 17.6817C88.7854 18.2976 88.4148 18.8579 87.9455 19.3302C87.4763 19.8025 86.9178 20.1774 86.3022 20.4333C85.6867 20.6891 85.0265 20.8209 84.3596 20.8208H81.2137V24.4262L77.8594 24.4361ZM84.3099 17.7051C84.5533 17.6888 84.7909 17.6246 85.0091 17.516C85.2273 17.4075 85.4216 17.2568 85.5809 17.0727C85.7402 16.8887 85.8612 16.6749 85.9369 16.4439C86.0126 16.2129 86.0414 15.9691 86.0218 15.7269C86.0218 14.5646 85.1981 13.6942 84.3 13.6942H81.2385V17.715L84.3099 17.7051Z"
        fill="#00A688"
      />
      <path
        d="M73.7517 16.0832C73.6624 14.5196 72.9764 13.0492 71.8341 11.9736C70.6919 10.8981 69.1801 10.2988 67.6087 10.2988C66.0374 10.2988 64.5256 10.8981 63.3833 11.9736C62.2411 13.0492 61.555 14.5196 61.4658 16.0832V24.4363H64.8598V21.7608H70.3775V24.4314H73.7765V16.0832H73.7517ZM64.8201 16.192V16.0387C64.8201 15.3094 65.1108 14.61 65.6282 14.0943C66.1455 13.5786 66.8473 13.2889 67.579 13.2889C68.3107 13.2889 69.0124 13.5786 69.5298 14.0943C70.0472 14.61 70.3378 15.3094 70.3378 16.0387V16.1376C70.34 16.1507 70.34 16.1641 70.3378 16.1772V18.65H64.8449L64.8201 16.192Z"
        fill="#00A688"
      />
      <path
        d="M56.1966 16.3205V16.0683C56.1058 14.4755 55.3958 12.9808 54.2174 11.9012C53.039 10.8216 51.4849 10.2422 49.8849 10.286C48.2849 10.3298 46.765 10.9932 45.6478 12.1356C44.5305 13.2779 43.9039 14.8093 43.9008 16.4046V21.2859H42.4122H42.1592V24.4115H57.6455H57.8986V21.3057H56.2016L56.1966 16.3205ZM50.0239 13.3531C50.3882 13.3531 50.7488 13.4246 51.0854 13.5636C51.4219 13.7025 51.7277 13.9061 51.9852 14.1629C52.2428 14.4196 52.4471 14.7243 52.5865 15.0598C52.7259 15.3952 52.7976 15.7547 52.7976 16.1177C52.7976 16.1177 52.7976 20.2226 52.7976 21.2958H47.275V16.1029C47.2697 15.7412 47.337 15.382 47.4732 15.0467C47.6093 14.7113 47.8114 14.4065 48.0676 14.1502C48.3238 13.8939 48.6289 13.6914 48.9649 13.5545C49.3009 13.4176 49.661 13.3491 50.0239 13.3531Z"
        fill="#00A688"
      />
    </svg>
  );
};
export default Logo;
