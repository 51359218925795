import { MicRegular, MicProhibitedRegular } from '@fluentui/react-icons';

import './VoiceInput.css';
import classNames from 'classnames';
import useChatStore from '../../hook/useStore';
import useVoiceRecorder from '../../hook/useVoiceRecorder';
import { useContext, useEffect, useState } from 'react';
import { TeamsFxContext } from '../../api/Context';

type TProps = {
  hide: any,
}

const VoiceInput = ({ hide }: TProps) => {

  const { socket } = useContext(TeamsFxContext);
  const { startRecording, stopRecording } = useVoiceRecorder();
  const [isRecording, setIsRecording] = useState(false);
  const setChatMessage = useChatStore((state) => state.addChatMessage);
  const setLoading = useChatStore((state) => state.setLoading);
  const setRecording = useChatStore((state) => state.setRecording);
  const isVoiceRecording = useChatStore((state) => state.isRecording);
  const isLoading = useChatStore((state) => state.isLoading);

  const handleStart = () => {
    startRecording();
    setRecording(true)
    setTimeout(() => {
      setIsRecording(true);
    }, 200);

  };

  const handleStop = () => {
    stopRecording();
    setRecording(false);
    setTimeout(() => {
      setIsRecording(false);
    }, 200);
  };

  useEffect(() => {
    socket?.on('message', (response) => {
      if (response.eventName === "userAudio.silenceDetected") {
        handleStop();
        setLoading(true)
      }
      else if (response.eventName === "ai.transcriptionReady") {
        setChatMessage({
          text: response.data.message,
          sender: 'You',
        });
      }
      else if (response.eventName === "ai.chatBotAnswered") {
        setChatMessage({
          text: response.data.message,
          sender: 'Chat',
        });
      }
      else if (response.eventName === "ai.textToAudioCompleted") {
        setLoading(false)
        setChatMessage({
          sender: 'Chat',
          audioBuffer: response.data.message,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames('voice-input', { hidden: hide })}>
      {isVoiceRecording && (
        <>
          <div className="loader"></div>
        </>
      )}
      <button type="button" disabled={isLoading}  className={classNames('mic', 'icon',
            { mic_big: isVoiceRecording },
            { disabled: isLoading })}
          onClick={!isVoiceRecording ? handleStart : handleStop}>
        {!isRecording ? (<MicRegular />) : (<MicProhibitedRegular />)}
      </button>
      {isVoiceRecording && (
        <>
          <div className="loader"></div>
        </>
      )}
    </div>
  );
};
export default VoiceInput;
