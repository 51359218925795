import useChatStore from '../../hook/useStore';
import ChatMessage from './modules/ChatMessage';
import './ChatWindow.css';
import LoadingMessage from './modules/LoadingMessage';

const ChatWindow = () => {
  const messages = useChatStore((state) => state.chatMessages);
  const isLoading = useChatStore((state) => state.isLoading);

  return (
    <div className="chat-window">
      {messages.map((message, index) => (
        <ChatMessage index={index} key={index} message={message} />
      ))}
      {isLoading && <LoadingMessage />}
    </div>
  );
};

export default ChatWindow;
