import classNames from 'classnames';

import './TextInput.css';
import useChatStore from '../../hook/useStore';

type TProps = {
  isVoiceRecording: any,
  message: any,
  setMessage: any

}


const TextInput = ({ isVoiceRecording, message, setMessage }: TProps) => {
  const isLoading = useChatStore((state) => state.isLoading);

  return (
    <div className={classNames('text-input', { hidden: isVoiceRecording })}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
        disabled={isLoading}
      />
    </div>
  );
};
export default TextInput;
