// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from '@fluentui/react-components';
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { useData, useTeamsUserCredential,  } from '@microsoft/teamsfx-react';
import MainContainer from './components/MainContainer';
import { TeamsFxContext } from './api/Context';
import config from './components/sample/lib/config';
import useSocket from './hook/useSocket';
import { useEffect, useState } from 'react';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

    const [userName, setUserName] = useState<string>("");

    useData(async () => {
      if (teamsUserCredential) {
        const userInfo = await teamsUserCredential.getUserInfo();
        console.log("userInfo = ", userInfo);
        setUserName(userInfo.displayName)
      }
    });
    const socket = useSocket();

  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential, socket, userName }}
    >
      <FluentProvider
        theme={
          themeString === 'dark'
            ? teamsDarkTheme
            : themeString === 'contrast'
              ? teamsHighContrastTheme
              : {
                ...teamsLightTheme,
                colorNeutralBackground3: '#eeeeee',
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <Router>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Routes>
              <Route path="/" element={<MainContainer />} />
              <Route path="*" element={<Navigate to={'/'} />}></Route>
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
