import classNames from 'classnames';
import { Avatar } from '@fluentui/react-components';

import '../ChatWindow.css';

const LoadingMessage = () => {
  

  return (
    <div
      className={classNames('chat-message', 'chat')}
    >
      <div className='chat-message-style loading'>
      <div className="chat-sender">

        {
        <Avatar
          name="Chat"
          size={32}
          image={{
            src: "/images/logo.png",
          }}
        /> }
        </div>
       <div className='chat-message-item loading'>
          <div className="loader small"></div>
        </div>
      </div>
    </div>
  );
};
export default LoadingMessage;
