import { useEffect, useRef } from 'react';

import { socket } from '../api/socket';
import { Socket } from 'socket.io-client';


const useSocket = () => {
  const socketRef = useRef<null | Socket>(null);

  useEffect(() => {
    if (socketRef.current == null) {
      socketRef.current = socket.connect();
    }
    const { current: socketConnection } = socketRef;

    return () => {
      socketConnection.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return socketRef.current;
};
export default useSocket;
