import classNames from 'classnames';
import {  useEffect, useRef, useState } from 'react';

import { TChatMessage } from '../../../hook/useStore';
import { generateAndPrepareAudio } from '../../../utils/audioGenerator';

import '../ChatWindow.css';
import { Avatar } from '@fluentui/react-components';

type TProps = {
  message: TChatMessage;
  index: any;
};

const ChatMessage = ({ message }: TProps) => {
  const [audioUrl, setAudioUrl] = useState<any>(null);

  useEffect(() => {
    if (message.audioBuffer) {
      generateAndPrepareAudio(message.audioBuffer).then((audio) => {
        setAudioUrl(audio);
      });
    }
  }, [message.audioBuffer]);

  const handleEnded = () => {
    console.log('Audio Ended');
  }

  return (
    <div
      className={classNames('chat-message', {
        chat: message.sender === 'Chat',
      })}
    >
      <div className='chat-message-style'>
      <div className="chat-sender">
        {
        message.sender === "Chat" ? 
        <Avatar
          name="Chat"
          size={32}
          image={{
            src: "/images/logo.png",
          }}
        /> 
        : ""}
        </div>
        {message.text && <div className='chat-message-item'>{message.text}</div>}
      </div>
      {audioUrl && message.audioBuffer &&
        (<audio src={audioUrl} controls autoPlay onEnded={handleEnded}>
          Your browser does not support the audio element.
        </audio>)}
    </div>
  );
};
export default ChatMessage;
