import { useContext } from 'react';
import { TeamsFxContext } from '../api/Context';
import ChatWindow from './ChatWindow/ChatWindow';
import ChatInput from './ChatInput/ChatInput';
import './MainContainer.css';
import Header from './Header/Header';

export default function MainContainer({ startRecording, stopRecording }: any) {
  const { themeString } = useContext(TeamsFxContext);

  return (
    <div
      className={
        themeString === 'default'
          ? 'light'
          : themeString === 'dark'
            ? 'dark'
            : 'contrast'
      }
    >
      <div className="chat-app">
        <Header />
        <ChatWindow />
        <ChatInput />
      </div>
    </div>
  );
}
