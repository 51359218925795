import Logo from '../images/Logo';

import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <Logo />
    </div>
  );
};
export default Header;
