import { useContext, useRef } from 'react';
import { TeamsFxContext } from '../api/Context';
import { generateRandomString } from '../utils/random';
import { CHUNK_DURATION } from '../constans';
import config from '../components/sample/lib/config';
import useChatStore from './useStore';

const useVoiceRecorder = () => {
  const { socket } = useContext(TeamsFxContext);
  const setLoading = useChatStore((state) => state.setLoading);

  const audioChunks: any = [];
  const mediaRecorderRef = useRef<any>(null);
  const audioContext = useRef<any>(null);
  let analyser;
  let streamSource;
  let counter = 1;
  const startTimeRef = useRef<number>(0);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioContext.current = new window.AudioContext();
    analyser = audioContext.current.createAnalyser();
    streamSource = audioContext.current.createMediaStreamSource(stream);
    streamSource.connect(analyser);

    mediaRecorderRef.current = new MediaRecorder(stream);
    startTimeRef.current = Date.now();

    mediaRecorderRef.current.ondataavailable = async (event: any) => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTimeRef.current;
    
      const isLastChunk = elapsedTime < CHUNK_DURATION;
      const byteData = await new Uint8Array(await event.data.arrayBuffer().toString('base64'));   
         
      socket?.emit('message', {
        eventName: 'userAudio.chunkCreated',
        socketId: socket.id,
        data: {
          userId: config.clientId,
          promptId: `${Date.now()}${generateRandomString()}`,
          chunkSequenceNumber: counter.toString().padStart(3, '0'),
          audioBuffer: byteData,
          isLastChunk: isLastChunk,
        },
      });

      counter = counter + 1;
      audioChunks.push(event.data);
      startTimeRef.current = currentTime; // Reset the start time for the next chunk
    };

    mediaRecorderRef.current.onstop = () => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    };

    mediaRecorderRef.current.start(CHUNK_DURATION);
  };

  const stopRecording = () => {
    setLoading(true)
    counter = 1;
    mediaRecorderRef.current.stop();
  };

  return {
    startRecording,
    stopRecording,
  };
};

export default useVoiceRecorder;
