import { create } from 'zustand';

export type TChatMessage = {
  text?: string;
  sender: string;
  audioBuffer?: any;
};

type TStore = {
  chatMessages: TChatMessage[];
  addChatMessage: (newMessage: TChatMessage) => void;
  isRecording: boolean;
  setRecording: (isRecording: boolean) => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
};

const useChatStore = create<TStore>((set) => ({
  chatMessages: [],
  isRecording: false,
  isLoading: false,
  setLoading: (isLoading: boolean) => set(() => ({ isLoading: isLoading })),
  setRecording: (isRecording: boolean) =>
    set(() => ({ isRecording: isRecording })),
  addChatMessage: (newMessage: TChatMessage) =>
    set((state: TStore) => ({
      chatMessages: [...state.chatMessages, newMessage],
    })),
}));

export default useChatStore;
