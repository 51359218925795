import { TeamsUserCredential } from '@microsoft/teamsfx';
import { createContext } from 'react';
import { Theme } from '@fluentui/react-components';
import { Socket } from 'socket.io-client';

export const TeamsFxContext = createContext<{
  theme?: Theme;
  themeString: string;
  teamsUserCredential?: TeamsUserCredential;
  socket: Socket | null;
  userName: string;
}>({
  theme: undefined,
  themeString: '',
  teamsUserCredential: undefined,
  socket: null,
  userName: '',
});
