import classNames from 'classnames';

import './TextInput.css';

const InputButton = ({ message, handleSend }: any) => {
  return (
    <button
      className={classNames('text-button',{ hidden: !message.length })}
      onClick={handleSend}
    >
      Send
    </button>
  );
};
export default InputButton;
